import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '@material-ui/core';
import MainForm from '../../components/MainForm';
import { FormTextField, FormCheckbox } from '../../components/FormControls';
import { API_USERS } from '../../utils/Consts';
import { requestError, destructApiWhere,axiosConfig,convertDate2String } from '../../utils/Helpers';
import axios from 'axios';
import { AppContext } from '../../app/AppProvider';
import {DatePickerKeyboard} from '../../utils/DatePicker';

const GroupForm = ({apiWhere,handleOnClose,id=0}) => {
  const { globalState } = useContext(AppContext);  
  const [errors,setErrors]=useState({});  
  const [circuralProgress,showCircuralProgress]=useState(false);  
  const [values, setValues] = useState({
    name: '',
    email: '',
    password: '',
    phone: '',
    datePurch: convertDate2String(new Date()),
    city: '',
    dbName: '',
    dbLogin: '',
    dbPassword: '',
    ifArchive: 0,
  });

  useEffect(() => {
    if (id>0) {
        axios.get(API_USERS+'/'+id,axiosConfig(globalState.token)).then(response => {
            setValues({
              name:response.data.name,
              email:response.data.email,
              phone:response.data.phone,
              datePurch:response.data.date_purch,
              city:response.data.city,
              dbName:response.data.db_name,
              dbLogin:response.data.db_login,
              dbPassword:response.data.db_passowrd,
              ifArchive:response.data.if_archive,
            });
        }).catch(error=>{ 
            setErrors({'error':requestError(error)});
        });  
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps     
  },[]);  

  const handleOnChange = e => {
    const val= e.target.type==="checkbox"?(e.target.checked?1:0):e.target.value;
    setValues({...values,[e.target.name]:val});
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    if (validateForm()) { 
       if (id === 0)
          showCircuralProgress(true); 
       const obj={'name':values.name,'email':values.email,'password':values.password,'phone':values.phone,'date_purch':values.datePurch,'city':values.city,'db_name':values.dbName,'db_login':values.dbLogin,'db_password':values.dbPassword,'if_archive':values.ifArchive};
       const promise=id>0?axios.put(API_USERS+'/'+id, obj,axiosConfig(globalState.token)):axios.post(API_USERS, obj,axiosConfig(globalState.token));
       promise.then(response => {
           const filterParams = destructApiWhere(apiWhere);
           const inFilter = (filterParams[0]===values.ifArchive);                
           const respObj = response.data;
           handleOnClose(id,respObj,inFilter);
       })
       .catch(error=>{ 
           setErrors({'error':requestError(error)});
       }); 

    }
  }  

  const validateForm=()=>{
    const validateErrors={};
    if (values.name.length<3) validateErrors['name']='Nazwa musi zawierać co najmniej 3 znaki.';
    if (id === 0) {
      if (values.password.length<3) validateErrors['password']='Podaj hasło.';  
      if (values.dbPassword.length<3) validateErrors['dbPassword']='Podaj hasło do bazy danych.';  
    }
    if (Object.keys(validateErrors).length>0) {
        setErrors(validateErrors);
        return false;
    } else {
        setErrors({});
        return true;
    }
  }       

  return (
      <MainForm title="Klient" id={id} onSubmit={handleOnSubmit} onCancel={handleOnClose} errors={errors} circuralProgress={circuralProgress}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
              <FormTextField name="name" value={values.name} label="Nazwa" onChange={handleOnChange} required minLength={3} maxLength={50} error={errors['name']}/>
          </Grid>  
          <Grid item xs={12}>
              <FormTextField name="email" value={values.email} label="Email" onChange={handleOnChange} required maxLength={50}/>
          </Grid>
          {
            id === 0 &&
            <Grid item xs={12}>
                <FormTextField required name="password" type="password" value={values.password} label="Hasło" onChange={handleOnChange} minLength={8} maxLength={50}/>
            </Grid>
          }
          <Grid item xs={12}>
                    <DatePickerKeyboard
                          name="datePurch"
                          label="Data zakupu"
                          value={values.datePurch}
                          onChange={date=>setValues({...values,datePurch:date})}
                          onClose={()=>null}
                          roller={true}
                          />
          </Grid>
          <Grid item xs={12}>
              <FormTextField name="phone" value={values.phone} label="Telefon" minLength={3} maxLength={10} onChange={handleOnChange}/>
          </Grid>                       
          <Grid item xs={12}>
              <FormTextField name="city" value={values.city} label="Miejscowość" minLength={3} maxLength={50} onChange={handleOnChange}/>
          </Grid>    
          <Grid item xs={12}>
              <FormTextField required name="dbName" value={values.dbName} label="Nazwa bazy danych" minLength={3} maxLength={50} onChange={handleOnChange} disabled={id>0}/>
          </Grid>
          <Grid item xs={12}>
              <FormTextField required name="dbLogin" value={values.dbLogin} label="Login do bazy danych" minLength={3} maxLength={50} onChange={handleOnChange}/>
          </Grid>                    
          <Grid item xs={12}>
              <FormTextField required={id ===0} name="dbPassword" type="password" value={values.dbPassword} label="Hasło do bazy danych" onChange={handleOnChange}/>
          </Grid>
          <Grid item xs={12}>
              <FormCheckbox  name="ifArchive" value={values.ifArchive} label="Archiwalny" onChange={handleOnChange} />
          </Grid>
        </Grid>              
      </MainForm>
  );
};

export default GroupForm;
