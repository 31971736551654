import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '@material-ui/core';
import MainForm from '../../components/MainForm';
import { FormTextField, FormCheckbox, FormSelect } from '../../components/FormControls';
import { API_HIVES, API_DR_CLIENTS } from '../../utils/Consts';
import { requestError, destructApiWhere, axiosConfig } from '../../utils/Helpers';
import axios from 'axios';
import { AppContext } from '../../app/AppProvider';

const HiveFormAdmin = ({apiWhere,handleOnClose,id=0}) => {
  const { globalState } = useContext(AppContext);  
  const [errors,setErrors]=useState({});  
  const [values, setValues] = useState({
    userMasterId: -1,
    uid: '',
    hasWeight: 0,
    ifArchive: 0,
  });
  const [listUsers,setListUsers] = useState({});

  useEffect(() => {
    if (id>0) {
      axios.all([axios.get(API_HIVES+'/'+id,axiosConfig(globalState.token)),axios.get(API_DR_CLIENTS,axiosConfig(globalState.token))]).then(axios.spread((response1,response2) => {
        console.log(response1);
          setValues({
            userMasterId:response1.data.user_master_id,
            uid:response1.data.uid,
            hasWeight:response1.data.has_weight,
            ifArchive:response1.data.if_archive,                        
          });

          setListUsers(response2.data);
      })).catch(error=> {
          setErrors({'error':requestError(error)});
      }); 
    } else {
      axios.get(API_DR_CLIENTS,axiosConfig(globalState.token)).then(response => {
        setListUsers(response.data); 
        setValues({...values,userMasterId:0});             
      }).catch(error=> {
          setErrors({'error':requestError(error)});
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps     
  },[]);    

  const handleOnChange = e => {
    const val= e.target.type==="checkbox"?(e.target.checked?1:0):e.target.value;
    setValues({...values,[e.target.name]:val});
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    if (validateForm()) {
       const obj={'user_master_id':values.userMasterId,'uid':values.uid,'has_weight':values.hasWeight,'if_archive':values.ifArchive};       
       const promise=id>0?axios.put(API_HIVES+'/'+id, obj,axiosConfig(globalState.token)):axios.post(API_HIVES, obj,axiosConfig(globalState.token));
       promise.then(response => {
           const filterParams = destructApiWhere(apiWhere);
           const inFilter = (filterParams[0]===values.ifArchive);             
           const respObj = response.data;
           handleOnClose(id,respObj,inFilter);
       })
       .catch(error=>{ 
           setErrors({'error':requestError(error)});
       });
    }
  }  

  const validateForm=()=>{
    const validateErrors={};
    if (values.userMasterId===0) validateErrors['userMasterId']='Wybierz klienta';
    if (values.uid.length<3) validateErrors['uid']='Nazwa musi zawierać co najmniej 2 znaki.';
    if (Object.keys(validateErrors).length>0) {
        setErrors(validateErrors);
        return false;
    } else {
        setErrors({});
        return true;
    }
  }   

  const handleOnDelete= () => {
    axios.delete(API_HIVES+'/'+id,axiosConfig(globalState.token)).then(response => {
      handleOnClose(id,{},false);
    }).catch(error=>{ 
        setErrors({'error':requestError(error)});
    });
  }     

  //circuralProgress={listGroups.length===0 || (id>0 && values.groupId===0)}
  return (
      <MainForm title="Ul" id={id} onSubmit={handleOnSubmit} onCancel={handleOnClose} onDelete={handleOnDelete} errors={errors} circuralProgress={values.groupId===-1}> 
        <Grid container spacing={3}>          
          <Grid item xs={12}>
            <FormSelect required name="userMasterId" value={values.userMasterId} label="Klient" onChange={handleOnChange} list={listUsers} emptyRowText="Wybierz klienta" error={errors['userMasterId']} disabled={id>0}/>
          </Grid>          
          <Grid item xs={12}>
              <FormTextField required name="uid" value={values.uid} label="Unikalny identyfikator ula" minLength={2} maxLength={10} onChange={handleOnChange}/>
          </Grid>  
          <Grid item xs={12}>
              <FormCheckbox name="hasWeight" value={values.hasWeight} label="Zamontowana waga" onChange={handleOnChange}/>
          </Grid>
          <Grid item xs={12}>
              <FormCheckbox name="ifArchive" value={values.ifArchive} label="Archiwalny" onChange={handleOnChange}/>
          </Grid>         
        </Grid>              
      </MainForm>
  );
};

export default HiveFormAdmin;
