import React from 'react';
import MainTable from '../../components/MainTable';
import {Container} from '@material-ui/core';
import ClientAdminForm from './ClientAdminForm';
import ClientAdminFilter from './ClientAdminFilter';
import {API_USERS,API_LIST} from '../../utils/Consts';

const columns=[
    {id:'id',displayName:'',numeric: true,search:false},
    {id:'name',displayName:'Nazwa',numeric: false,search:true},
    {id:'email',displayName:'Email',numeric: false,search:true},
    {id:'phone',displayName:'Telefon',numeric: false,search:true},
    {id:'city',displayName:'Miasto',numeric: false,search:true},    
    {id:'date_purch',displayName:'Data zakupu',numeric: false,search:true}
]

const ClientAdminList = () => {
    return ( 
        <Container maxWidth={false}>
            <MainTable EditForm={ClientAdminForm} title="Klienci" ListFilter={ClientAdminFilter} apiUrl={API_USERS+'/'+API_LIST+'/'} apiUrlWhere="0" columns={columns} sortBy="name" csvName="lista_klientow.csv"/>
        </Container>
     );
}
 
export default ClientAdminList;

